import { template as template_0d8008a5c5e94377a082633e24e0fe6a } from "@ember/template-compiler";
import PixTooltip from '@1024pix/pix-ui/components/pix-tooltip';
import { t } from 'ember-intl';
const isAcquired = (badge, acquiredBadges = [])=>{
    let acquired = false;
    acquiredBadges.forEach((acquiredBadge)=>{
        if (acquiredBadge.id === badge.id) {
            acquired = true;
        }
    });
    return acquired;
};
export default template_0d8008a5c5e94377a082633e24e0fe6a(`
  {{#each @badges as |badge|}}
    <PixTooltip @id="badge-tooltip-{{badge.id}}">
      <:triggerElement>
        <img
          src={{badge.imageUrl}}
          alt={{badge.altMessage}}
          tabindex="0"
          class={{unless (isAcquired badge @acquiredBadges) "badge--unacquired"}}
          aria-describedby="badge-tooltip-{{badge.id}}"
        />
      </:triggerElement>
      <:tooltip>
        {{badge.title}}
        -
        {{if
          (isAcquired badge @acquiredBadges)
          (t "pages.campaign-results.table.badge-tooltip.acquired")
          (t "pages.campaign-results.table.badge-tooltip.unacquired")
        }}
      </:tooltip>
    </PixTooltip>
  {{/each}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
