import { template as template_e39ee55e978641348abe7eb4b3506564 } from "@ember/template-compiler";
import PixIndicatorCard from '@1024pix/pix-ui/components/pix-indicator-card';
import { t } from 'ember-intl';
export default template_e39ee55e978641348abe7eb4b3506564(`
  <PixIndicatorCard
    @title={{if
      @shouldDisplayAssessmentLabels
      (t "cards.submitted-count.title")
      (t "cards.submitted-count.title-profiles")
    }}
    @iconName="inboxIn"
    @color="green"
    @info={{t "cards.submitted-count.information"}}
    @loadingMessage={{t "cards.submitted-count.loader"}}
    @isLoading={{@isLoading}}
    ...attributes
  >
    <:default>{{@value}}</:default>
  </PixIndicatorCard>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
