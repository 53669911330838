import { template as template_5dbcadcf5a7f41e3a8b56770ba9b8069 } from "@ember/template-compiler";
import PixPagination from '@1024pix/pix-ui/components/pix-pagination';
import PixTable from '@1024pix/pix-ui/components/pix-table';
import PixTableColumn from '@1024pix/pix-ui/components/pix-table-column';
import PixTag from '@1024pix/pix-ui/components/pix-tag';
import { t } from 'ember-intl';
import getService from '../../helpers/get-service.js';
function statusColor(status) {
    return {
        'not-started': 'tertiary',
        completed: 'success',
        started: 'secondary'
    }[status];
}
export default template_5dbcadcf5a7f41e3a8b56770ba9b8069(`
  {{#if @missionLearners}}
    <PixTable
      @variant="orga"
      class="table"
      @data={{@missionLearners}}
      @caption={{t "pages.missions.mission.table.activities.caption" missionName=@mission.name}}
    >
      <:columns as |missionLearner context|>
        <PixTableColumn @context={{context}}>
          <:header>{{t "pages.missions.mission.table.activities.headers.first-name"}}</:header>
          <:cell>{{missionLearner.firstName}}</:cell>
        </PixTableColumn>

        <PixTableColumn @context={{context}}>
          <:header>{{t "pages.missions.mission.table.activities.headers.last-name"}}</:header>
          <:cell>{{missionLearner.lastName}}</:cell>
        </PixTableColumn>

        <PixTableColumn @context={{context}}>
          <:header>{{t "pages.missions.mission.table.activities.headers.division"}}</:header>
          <:cell>
            {{missionLearner.division}}
          </:cell>
        </PixTableColumn>

        <PixTableColumn @context={{context}}>
          <:header>{{t "pages.missions.mission.table.activities.headers.status"}}</:header>
          <:cell>
            <PixTag @color={{statusColor missionLearner.missionStatus}}>{{t missionLearner.displayableStatus}}</PixTag>
          </:cell>
        </PixTableColumn>
      </:columns>
    </PixTable>

    {{#let (getService "service:intl") as |intl|}}
      <PixPagination @pagination={{@missionLearners.meta}} @locale={{intl.primaryLocale}} />
    {{/let}}

  {{else}}
    <div class="table__empty content-text">
      {{t "pages.missions.mission.table.activities.no-data"}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
