import { template as template_501dd9b6365540ef96cb554defd8942b } from "@ember/template-compiler";
import PixBlock from '@1024pix/pix-ui/components/pix-block';
export default template_501dd9b6365540ef96cb554defd8942b(`
  <PixBlock class="empty-state">
    <img src="{{this.rootURL}}/images/empty-state-participants.svg" alt="" role="none" />
    <p class="participants-empty-state__text">{{@infoText}}</p>
    <p class="participants-empty-state__text">{{@actionText}}</p>
  </PixBlock>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
