import { template as template_89cbeba6c6614b979cd86d35f92fd48a } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_89cbeba6c6614b979cd86d35f92fd48a(`
  <p class="screen-reader-only">{{t "charts.participants-by-mastery-percentage.loader"}}</p>
  <div class="participants-by-mastery-percentage--loader placeholder-box" aria-hidden="true"></div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
