import { template as template_0f474816219f4506a979f4fefcc55475 } from "@ember/template-compiler";
export default template_0f474816219f4506a979f4fefcc55475(`
  <div class="information" ...attributes>
    <dt class="information__title">
      {{yield to="title"}}
    </dt>
    <dd class="information__content {{@contentClass}}">
      {{yield to="content"}}
    </dd>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
