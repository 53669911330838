import { template as template_f61c57a75591489eab4b023a3d3bd1b1 } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
export default template_f61c57a75591489eab4b023a3d3bd1b1(`
  <li class="dropdown__item dropdown__item--link">
    <LinkTo @route={{@linkTo}} class="link" ...attributes>
      {{yield}}
    </LinkTo>
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
