import { template as template_dfcdae004bf54090a8008303ac9bf64b } from "@ember/template-compiler";
import PixBannerAlert from '@1024pix/pix-ui/components/pix-banner-alert';
import { htmlSafe } from '@ember/template';
import isEmpty from 'lodash/isEmpty';
import ENV from 'pix-orga/config/environment';
import textWithMultipleLang from '../../helpers/text-with-multiple-lang.js';
function isEnabled() {
    return !isEmpty(ENV.APP.BANNER_CONTENT) && !isEmpty(ENV.APP.BANNER_TYPE);
}
function bannerType() {
    return ENV.APP.BANNER_TYPE;
}
function bannerContent() {
    return htmlSafe(ENV.APP.BANNER_CONTENT);
}
export default template_dfcdae004bf54090a8008303ac9bf64b(`
  {{#if (isEnabled)}}
    <PixBannerAlert @type={{(bannerType)}} class="sticker-banner">
      {{textWithMultipleLang (bannerContent)}}
    </PixBannerAlert>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
