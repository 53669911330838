import { template as template_bb8f4edad61540a9a5d7c7c0471c6bf4 } from "@ember/template-compiler";
export default template_bb8f4edad61540a9a5d7c7c0471c6bf4(`
  <div class="form-field">
    <div class="form-field__element">
      {{yield}}
    </div>

    <div class="form-field__information">
      {{yield to="information"}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
