import { template as template_fa6b812111fa4cd2bbdf2bead54e270c } from "@ember/template-compiler";
import { concat } from '@ember/helper';
export default template_fa6b812111fa4cd2bbdf2bead54e270c(`
  <th
    class="{{if @size (concat 'table__column--' @size)}}{{if @align (concat ' table__column--' @align)}}"
    ...attributes
  >
    {{yield}}
  </th>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
