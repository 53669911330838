import { template as template_9fb16dffe338465c9c8cc4a7f3e567aa } from "@ember/template-compiler";
import PixButtonLink from '@1024pix/pix-ui/components/pix-button-link';
import PixTabs from '@1024pix/pix-ui/components/pix-tabs';
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
import PageTitle from '../ui/page-title';
export default template_9fb16dffe338465c9c8cc4a7f3e567aa(`
  <header>
    <PageTitle>
      <:title>
        {{t "pages.campaigns-list.title"}}
      </:title>
      <:tools>
        <PixButtonLink
          @route="authenticated.campaigns.new"
          class="campaign-list-header__create-campaign-button hide-on-mobile"
        >
          {{t "pages.campaigns-list.action.create"}}
        </PixButtonLink>
      </:tools>
    </PageTitle>

    <PixTabs @variant="orga" class="campaign-list-header__tabs" @ariaLabel={{t "pages.campaigns-list.navigation"}}>
      <LinkTo @route="authenticated.campaigns.list.my-campaigns">
        {{t "pages.campaigns-list.tabs.my-campaigns"}}
      </LinkTo>
      <LinkTo @route="authenticated.campaigns.list.all-campaigns">
        {{t "pages.campaigns-list.tabs.all-campaigns"}}
      </LinkTo>
    </PixTabs>
  </header>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
