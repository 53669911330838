import { template as template_f04d364176fd455a92288c519f3d5a6b } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
export default class InElement extends Component {
    @tracked
    destinationElement;
    @service
    elementHelper;
    constructor(){
        super(...arguments);
        if (this.args.waitForElement) {
            this.elementHelper.waitForElement(this.args.destinationId).then((element)=>{
                this.destinationElement = element;
            });
        } else {
            this.destinationElement = document.getElementById(this.args.destinationId);
        }
    }
    static{
        template_f04d364176fd455a92288c519f3d5a6b(`
    {{#if this.destinationElement}}
      {{#in-element this.destinationElement}}
        {{yield}}
      {{/in-element}}
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
