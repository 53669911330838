import { template as template_2f2c5b443e9c4944bda8f17ce707008e } from "@ember/template-compiler";
export default template_2f2c5b443e9c4944bda8f17ce707008e(`
  <dl class="information-wrapper" ...attributes>
    {{yield}}
  </dl>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
