import { template as template_afe01dc728c74c45aa70bf4a79ada540 } from "@ember/template-compiler";
import PixBannerAlert from '@1024pix/pix-ui/components/pix-banner-alert';
import textWithMultipleLang from '../../helpers/text-with-multiple-lang.js';
export default template_afe01dc728c74c45aa70bf4a79ada540(`
  {{#each @banners as |banner|}}
    <PixBannerAlert @type={{banner.severity}}>
      {{textWithMultipleLang banner.message}}
    </PixBannerAlert>
  {{/each}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
