import { template as template_c675c195a2a7477ca4c2ccfd408177d3 } from "@ember/template-compiler";
export default template_c675c195a2a7477ca4c2ccfd408177d3(`
  <div class="action-bar">
    <p class="action-bar__informations">
      {{yield to="information"}}
    </p>
    <div class="action-bar__actions">
      {{yield to="actions"}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
