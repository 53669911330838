import { template as template_b9059f4ea7c942ef9b8f0bc9c935fe76 } from "@ember/template-compiler";
import PixIconButton from '@1024pix/pix-ui/components/pix-icon-button';
export default template_b9059f4ea7c942ef9b8f0bc9c935fe76(`
  <PixIconButton
    aria-label={{@ariaLabel}}
    @iconName="{{if @isOpen 'chevronTop' 'chevronBottom'}}"
    aria-expanded="{{@isOpen}}"
    @triggerAction={{@onClick}}
    @withBackground={{false}}
    @size="small"
    @color="dark-grey"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
