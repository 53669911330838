import { template as template_5624199d5ba246c0b07de9d65706b2e7 } from "@ember/template-compiler";
import onClickOutside from 'ember-click-outside/modifiers/on-click-outside';
export default template_5624199d5ba246c0b07de9d65706b2e7(`
  {{#if @display}}
    <ul class="dropdown__content" ...attributes {{onClickOutside @close}}>
      {{yield}}
    </ul>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
