import { template as template_41dcce2c50504ff68b81a591c886fefb } from "@ember/template-compiler";
import PixSelect from '@1024pix/pix-ui/components/pix-select';
import { action } from '@ember/object';
import Component from '@glimmer/component';
export default class SelectFilter extends Component {
    @action
    onChange(value) {
        const { triggerFiltering, field } = this.args;
        triggerFiltering(field, value);
    }
    static{
        template_41dcce2c50504ff68b81a591c886fefb(`
    <PixSelect
      @screenReaderOnly={{true}}
      @onChange={{this.onChange}}
      @options={{@options}}
      @value={{@selectedOption}}
      @placeholder={{@emptyOptionLabel}}
    >
      <:label>{{@label}}</:label>
    </PixSelect>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
