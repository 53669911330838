import { template as template_0aa9782a5a6947f692e19fc366d78c79 } from "@ember/template-compiler";
import PixTag from '@1024pix/pix-ui/components/pix-tag';
import { service } from '@ember/service';
import Component from '@glimmer/component';
export default class ParticipationStatus extends Component {
    @service
    intl;
    get color() {
        const { status } = this.args;
        return COLORS[status];
    }
    get label() {
        const { status, campaignType } = this.args;
        return this.intl.t(`components.participation-status.${status}-${campaignType === 'EXAM' ? 'ASSESSMENT' : campaignType}`);
    }
    static{
        template_0aa9782a5a6947f692e19fc366d78c79(`
    <PixTag @color={{this.color}}>
      {{this.label}}
    </PixTag>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
const COLORS = {
    STARTED: 'yellow-light',
    TO_SHARE: 'purple-light',
    SHARED: 'green-light'
};
