import { template as template_e5473c16397444d78f208d72c202801c } from "@ember/template-compiler";
import PixNotificationAlert from '@1024pix/pix-ui/components/pix-notification-alert';
import { t } from 'ember-intl';
import { gt } from 'ember-truth-helpers';
export default template_e5473c16397444d78f208d72c202801c(`
  {{#if (gt @occupied @total)}}
    <PixNotificationAlert @type="error" @withIcon={{true}}>
      {{t "banners.over-capacity.message"}}
    </PixNotificationAlert>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
