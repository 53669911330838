import { template as template_dd11e557392a45c5a1cdb88e08c5a527 } from "@ember/template-compiler";
import PixIndicatorCard from '@1024pix/pix-ui/components/pix-indicator-card';
import { t } from 'ember-intl';
export default template_dd11e557392a45c5a1cdb88e08c5a527(`
  <PixIndicatorCard
    @title={{t "cards.participants-count.title"}}
    @iconName="users"
    @color="blue"
    @info={{t "cards.participants-count.information"}}
    @loadingMessage={{t "cards.participants-count.loader"}}
    @isLoading={{@isLoading}}
    ...attributes
  >
    <:default>{{@value}}</:default>
  </PixIndicatorCard>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
