import { template as template_4b0ec593067b4855b06bde5c531e8b0f } from "@ember/template-compiler";
import { t } from 'ember-intl';
import { gt } from 'ember-truth-helpers';
export default template_4b0ec593067b4855b06bde5c531e8b0f(`
  <div class="table__column--center">
    {{#if (gt @tutorials.length 0)}}
      {{t "pages.campaign-review.table.analysis.column.tutorial-count.value" count=@tutorials.length}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
