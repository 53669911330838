import { template as template_5616e16b6a76447e80fbf843cf125044 } from "@ember/template-compiler";
export default template_5616e16b6a76447e80fbf843cf125044(`
  <section class="import-card">
    <h2 class="import-card__title">{{@cardTitle}}</h2>
    <div class="import-card__details">
      {{yield to="cardDetails"}}
    </div>

    <div class="import-card__footer">
      {{yield to="cardFooter"}}
    </div>
  </section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
