import { template as template_ceb55e95d9ce4754942cd3b27866311d } from "@ember/template-compiler";
import { t } from 'ember-intl';
import TooltipWithIcon from '../../ui/tooltip-with-icon';
export default template_ceb55e95d9ce4754942cd3b27866311d(`
  <span class="evolution-header">

    {{t "pages.campaign-results.table.column.evolution"}}

    <TooltipWithIcon
      @iconName="help"
      @content={{@tooltipContent}}
      @ariaHiddenIcon={{true}}
      class="tooltip-with-icon-small"
    />
  </span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
